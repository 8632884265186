export default {
  props: {
    accountNo: {
      type: String,
      required: false,
    },
    birthDate: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      transferRegists: null,
      batchesRegists: null,
      arses: null,
    }
  },
  methods: {
    async getBnkAccountarses() {
      if (!this.accountNo || !this.birthDate) return

      const data = await this.$fundaApi.getBnkAccountarses({
        accountNo: this.accountNo,
        birthday: this.birthDate,
      })

      this.arses = data
    },
    async getTransferRegists() {
      if (!this.accountNo) return

      const data = await this.$fundaApi.getBnkAccountTransferRegists(
        this.accountNo
      )
      this.transferRegists = data
    },
    async getBatchesRegists() {
      if (!this.accountNo) return

      const data = await this.$fundaApi.getBnkAccountBatchesRegists(
        this.accountNo
      )
      this.batchesRegists = data
    },
  },
}
