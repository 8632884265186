<template>
  <v-data-table
    :loading="!batchesRegists"
    :items="batchesRegists || []"
    :headers="headers"
    :items-per-page="5"
  >
    <template v-slot:[`item.bankCd`]="{ item: { bankCd } }">
      {{ bankCd | bankCodeName }}
    </template>
    <template v-slot:[`item.crDate`]="{ item: { crDate } }">
      {{ crDate | moment('yyyy-MM-DD HH:mm:ss') }}
    </template>
    <template v-slot:[`item.upDate`]="{ item: { upDate } }">
      {{ upDate | moment('yyyy-MM-DD HH:mm:ss') }}
    </template>
    <template v-slot:[`item.useYn`]="{ item: { useYn } }">
      <span :class="{ 'primary--text': useYn === 'Y' }">
        {{ useYn === 'Y' ? '사용' : '미사용' }}
      </span>
    </template>
    <template v-slot:[`item.isLock`]="{ item: { isLock } }">
      <span :class="{ 'error--text': isLock }">
        {{ isLock ? '락계좌' : '일반계좌' }}
      </span>
    </template>
  </v-data-table>
</template>

<script>
import collectionAccountMixin from './collectionAccountMixin.js'

export default {
  mixins: [collectionAccountMixin],
  data() {
    return {
      headers: [
        { text: 'idx', value: 'idx', sortable: false },
        {
          text: '락계좌여부 (isLock)',
          value: 'isLock',
          sortable: false,
          width: 100,
        },
        {
          text: '배치출금여부 (useYn)',
          value: 'useYn',
          sortable: false,
          width: 100,
        },
        { text: 'payrNo', value: 'payrNo', sortable: false },
        { text: '요청시간', value: 'crDate', sortable: false, width: 180 },
        { text: '수정시간', value: 'upDate', sortable: false, width: 180 },
        { text: 'inoutType', value: 'inoutType', sortable: false },
        { text: '은행', value: 'bankCd', sortable: false },
        { text: '계좌번호', value: 'bankAccount', sortable: false },
      ],
    }
  },
  async created() {
    await this.getBatchesRegists()
  },
}
</script>

<style></style>
